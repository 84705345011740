import React from "react"

import Layout from "../components/layout"
import Header from "../components/header"
import SEO from "../components/seo"
import Loadable from "@loadable/component"

const ProductsListing = Loadable(() => import('../components/ProductsListing'))

const IndexPage = () => (
  <Layout>
    <SEO title="Marine Serre Archive" />
    <Header siteTitle="Marine Serre Archive" />
    <ProductsListing />
  </Layout>
)

export default IndexPage
